import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import React, { useState, useEffect, useCallback } from "react";
import updateCandidate from "../../services/updateCandidate";
import { getResponseSheetName, getUID } from "../../utils/authUtils";
import { useNavigate } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { parseLocations } from "../../utils/settingUtils";
import useCandidate from "../../components/useCandidate";
import {
  convertLocationToSettings,
} from "../../utils/locationUtils";
import { LocationData } from "../../../api/src/interfaces/LocationData";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function WalkinInterviewForm() {
  const [formData, setFormData] = useState({
    name: "",
    code: "",
  });
  const navigate = useNavigate();
  const candidate = useCandidate();
  const [submitting, setSubmitting] = useState(false);

  const [errOpen, setErrOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");

  const errHandleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrOpen(false);
  };

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    let uid = getUID();
    if (!uid) {
      setSubmitting(false);
      navigate("/");
      return;
    }
    getSettings(getResponseSheetName())
      .then(({ data, error }) => {
        let locations!: ReturnType<typeof parseLocations>;
        if (candidate?.locationAssigned) {
          locations = parseLocations(
            convertLocationToSettings(
              JSON.parse(candidate.locationAssigned) as LocationData
            )
          );
        } else {
          locations = parseLocations(data);
        }

        const checkInCodes = locations.map((x) =>
          String(x.setting_walkin_checkin_code)
        );

        if (!checkInCodes.includes(String(formData.code))) {
          throw new Error("Wrong code");
        }
        return updateCandidate(
          String(uid),
          {
            interviewerName: formData.name,
            // interviewerCode: formData.code,
          },
          getResponseSheetName()
        );
      })
      .then((res) => {
        let { data, error } = res;
        if (error) {
          throw new Error("Unable to update candidate. Internal server error.");
          return;
        }
        console.log("Success");
        setSubmitting(false);
        navigate("/walkin/interviewSuccess");
      })
      .catch((err) => {
        setSubmitting(false);
        console.error(err);
        setErrMsg(err.message);
        setErrOpen(true);
      });
  }, [candidate, formData]);

  return (
    <SimpleIphonePromptLayout>
      <Snackbar open={errOpen} autoHideDuration={6000} onClose={errHandleClose}>
        <Alert onClose={errHandleClose} severity="error" sx={{ width: "100%" }}>
          {errMsg}
        </Alert>
      </Snackbar>
      <div>Interviewer Details</div>
      <div style={{ color: "#6656da", fontWeight: "bold" }}>
        Name of interviewer
      </div>
      <input
        placeholder="Interviewer Name"
        type="text"
        onChange={(evt) => {
          setFormData((prevState) => {
            return {
              ...prevState,
              name: evt.target.value,
            };
          });
        }}
        style={{
          width: "100%",
          textAlign: "center",
          padding: "10px",
          borderRadius: "15px",
          border: "0 solid rgba(0,0,0,0)",
          outline: "3px solid #6656da",
        }}
      ></input>
      <div style={{ color: "#6656da", fontWeight: "bold" }}>Pass code</div>
      <input
        placeholder="NO. XXX"
        onChange={(evt) => {
          setFormData((prevState) => {
            return {
              ...prevState,
              code: evt.target.value,
            };
          });
        }}
        type="text"
        style={{
          width: "100%",
          textAlign: "center",
          padding: "10px",
          borderRadius: "15px",
          border: "0 solid rgba(0,0,0,0)",
          outline: "3px solid #6656da",
        }}
      ></input>
      <div
        onClick={onSubmit}
        style={{
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          backgroundColor: submitting ? "grey" : "#6656da",
          borderRadius: "10px",
          padding: "10px",
          width: "100%",
        }}
      >
        {submitting ? "Submitting..." : "Submit"}
      </div>
    </SimpleIphonePromptLayout>
  );
}
