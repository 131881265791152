import { SETTINGS } from "../../api/src/interfaces/sheets";
import { LocationData } from "../../api/src/interfaces/LocationData";
export function convertLocationToSettings(input: LocationData) {
  const fakeSettings: Partial<SETTINGS> = {
    setting_walkin_checkin_gmap_link: input.googleMapLink,
    setting_walkin_checkin_waze_link: input.wazeLink,
    setting_walkin_checkin_latlong: input.latLong,
    setting_walkin_checkin_range: input.checkInRangeMeters,
    setting_walkin_checkin_address: input.fullAddress,
    setting_walkin_checkin_code: input.checkInCode,
  };
  return fakeSettings as SETTINGS;
}
