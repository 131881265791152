export default function WalkinLanding() {
  return (
    <div
      style={{
        gridArea: "2 / 2 / 3 / 3",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      To check in, please use the link from interview invitation email
    </div>
  );
}
