import React from "react";
import { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";

import WalkinLayout from "./layouts/WalkinLayout";

import "bootstrap/dist/css/bootstrap.min.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import LoadingComponent from "./components/LoadingComponent";
import * as BrandsIcons from "@fortawesome/free-brands-svg-icons";
import WalkinConsultant from "./pages/walkin/WalkinConsultant";
import WalkinCheckIn from "./pages/walkin/WalkinCheckIn";
import WalkinReady from "./pages/walkin/WalkinReady";
import NotFoundPage from "./pages/404";
import AuthenticatePage from "./pages/AuthenticatePage";
import AuthLayout from "./layouts/AuthLayout";
import WalkinCheckInSuccess from "./pages/walkin/WalkinCheckInSuccess";
import WalkinLocation from "./pages/walkin/WalkinLocation";
import WalkinInterview from "./pages/walkin/WalkinInterview";
import WalkinInterviewForm from "./pages/walkin/WalkinInterviewForm";
import WalkinInterviewSuccess from "./pages/walkin/WalkinInterviewSuccess";
import WalkinLanding from "./pages/walkin/WalkinLanding";
import WalkinQrResume from "./pages/walkin/WalkinQrResume";
import PublicCandidateResume from "./pages/public/PublicCandidateResume";
import PublicLayout from "./layouts/PublicLayout";
const solidIconList = Object.keys(solidIcons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon: string) => (solidIcons as any)[icon]);

const brandsIconList = Object.keys(BrandsIcons)
  .filter((key) => key !== "fab" && key !== "prefix")
  .map((icon: string) => (BrandsIcons as any)[icon]);

library.add(...solidIconList);
library.add(...brandsIconList);

const router = createBrowserRouter([
  {
    path: "/",
    element: <WalkinLayout></WalkinLayout>,
    children: [
      {
        path: "",
        element: <WalkinLanding></WalkinLanding>,
      },
    ],
  },
  {
    path: "/404",
    element: <NotFoundPage></NotFoundPage>,
  },
  {
    path: "/auth",
    element: <AuthenticatePage></AuthenticatePage>,
  },
  {
    path: "/public",
    element: <PublicLayout></PublicLayout>,
    children: [
      {
        path: "candidateResume",
        element: <PublicCandidateResume></PublicCandidateResume>,
      },
    ],
  },
  {
    path: "/walkin",
    element: <AuthLayout></AuthLayout>,
    children: [
      {
        path: "",
        element: <WalkinLayout></WalkinLayout>,
        children: [
          {
            path: "help",
            element: <WalkinConsultant></WalkinConsultant>,
          },
          {
            path: "checkin",
            element: <WalkinCheckIn></WalkinCheckIn>,
          },
          {
            path: "checkinSuccess",
            element: <WalkinCheckInSuccess></WalkinCheckInSuccess>,
          },
          {
            path: "myresume",
            element: <WalkinQrResume />,
          },
          {
            path: "ready",
            element: <WalkinReady></WalkinReady>,
          },
          {
            path: "location",
            element: <WalkinLocation></WalkinLocation>,
          },
          {
            path: "interview",
            element: <WalkinInterview></WalkinInterview>,
          },
          {
            path: "interviewForm",
            element: <WalkinInterviewForm></WalkinInterviewForm>,
          },
          {
            path: "interviewSuccess",
            element: <WalkinInterviewSuccess></WalkinInterviewSuccess>,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingComponent></LoadingComponent>}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
