import React, { useState, useEffect } from "react";
import { SETTINGS } from "../../api/src/interfaces/sheets";
import { getSettings } from "../services/getSettings";
import { getResponseSheetName } from "../utils/authUtils";
export default function useSettings(forceRefetch = false) {
  const localStorageKey = "settingsData";
  const [settings, setSettings] = useState<Partial<SETTINGS> | null>(null);
  useEffect(() => {
    const run = async () => {
      const cache = localStorage.getItem(localStorageKey);
      if (cache && !forceRefetch) {
        const { data, lastUpdated } = JSON.parse(cache);
        if (new Date().getTime() - lastUpdated < 300000) {
          setSettings(data);
          return;
        }
      }
      const { data, error } = await getSettings(getResponseSheetName());

      setSettings(data);
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({ data: data, lastUpdated: new Date().getTime() })
      );
    };

    run()
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return React.useMemo(() => settings, [settings]);
}
