import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { storeResponseSheetName, storeUID } from "../utils/authUtils";

export default function AuthenticatePage() {
  const navigate = useNavigate();
  let [urlParams, setUrlParams] = useSearchParams();
  useEffect(() => {
    const candidateId = urlParams.get("candidateId");
    const responseSheetName = urlParams.get("responseSheetName");

    if (!candidateId || !responseSheetName) {
      navigate("/");
    }
    localStorage.clear();
    storeUID(String(candidateId));
    storeResponseSheetName(String(responseSheetName));
    navigate("/walkin/checkin");
  }, []);
  return <div></div>;
}
