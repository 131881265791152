import { SETTINGS } from "../../api/src/interfaces/sheets";

export function parseLocations(settings: SETTINGS) {
  let {
    setting_walkin_checkin_gmap_link,
    setting_walkin_checkin_waze_link,
    setting_walkin_checkin_latlong,
    setting_walkin_checkin_range,
    setting_walkin_checkin_address,
    setting_walkin_checkin_code
  } = settings;
  const arr_setting_walkin_checkin_gmap_link =
    String(setting_walkin_checkin_gmap_link).split("|");
  const arr_setting_walkin_checkin_waze_link =
    String(setting_walkin_checkin_waze_link).split("|");
  const arr_setting_walkin_checkin_latlong =
    String(setting_walkin_checkin_latlong).split("|");
  const arr_setting_walkin_checkin_range =
    String(setting_walkin_checkin_range).split("|");
  const arr_setting_walkin_checkin_address =
    String(setting_walkin_checkin_address).split("|");
  const arr_setting_walkin_checkin_code =
    String(setting_walkin_checkin_code).split("|");

  const max = Math.max(
    arr_setting_walkin_checkin_gmap_link.length,
    arr_setting_walkin_checkin_waze_link.length,
    arr_setting_walkin_checkin_latlong.length,
    arr_setting_walkin_checkin_range.length,
    arr_setting_walkin_checkin_address.length,
    arr_setting_walkin_checkin_code.length
  );

  const locations: {
    setting_walkin_checkin_gmap_link: string;
    setting_walkin_checkin_waze_link: string;
    setting_walkin_checkin_latlong: string;
    setting_walkin_checkin_range: string;
    setting_walkin_checkin_address: string;
    setting_walkin_checkin_code: string;
  }[] = [];
  for (let i = 0; i < max; i++) {
    const element_setting_walkin_checkin_gmap_link =
      arr_setting_walkin_checkin_gmap_link[i];
    const element_setting_walkin_checkin_waze_link =
      arr_setting_walkin_checkin_waze_link[i];
    const element_setting_walkin_checkin_latlong =
      arr_setting_walkin_checkin_latlong[i];
    const element_setting_walkin_checkin_range =
      arr_setting_walkin_checkin_range[i];
    const element_setting_walkin_checkin_address =
      arr_setting_walkin_checkin_address[i];
    const element_setting_walkin_checkin_code =
      arr_setting_walkin_checkin_code[i];
    locations.push({
      setting_walkin_checkin_gmap_link:
        element_setting_walkin_checkin_gmap_link,
      setting_walkin_checkin_waze_link:
        element_setting_walkin_checkin_waze_link,
      setting_walkin_checkin_latlong: element_setting_walkin_checkin_latlong,
      setting_walkin_checkin_range: element_setting_walkin_checkin_range,
      setting_walkin_checkin_address: element_setting_walkin_checkin_address,
      setting_walkin_checkin_code: element_setting_walkin_checkin_code
    });
  }
  return locations
}
