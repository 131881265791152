import Cookies from "universal-cookie";
export function storeUID(uid: string) {
  localStorage.setItem("uid", uid);
  const cookies = new Cookies();
  cookies.set("uid", uid, { path: "/" });
}

export function getUID() {
  return localStorage.getItem("uid") as string;
}

export function storeResponseSheetName(responseSheetName: string) {
  localStorage.setItem("responseSheetName", responseSheetName);
}

export function getResponseSheetName() {
  return localStorage.getItem("responseSheetName") as string;
}

export function isAuth() {
  const cookies = new Cookies();
  let uid = cookies.get("uid");
  if (!uid) {
    uid = localStorage.getItem("uid");
    if (!uid) return false;
    storeUID(uid);
  }
  return true;
}

export function logOff() {
  localStorage.removeItem("uid");
  const cookies = new Cookies();
  cookies.remove("uid", { path: "/" });

  // TODO: Clear sidebar cache
  window.location.href = "/";
}
