import rootUrl from "../APIRoot";
import { SETTINGS } from "../../api/src/interfaces/sheets";
export async function getSettings(responseSheetName: string): Promise<{
  data: SETTINGS;
  error: any;
}> {
  let settingsFetch = await fetch(`${rootUrl}/settings/${responseSheetName}`);
  let settings = await settingsFetch.json();
  let { data, error } = settings;
  return { data, error };
}
