import celebrateVertical from "./../../assets/celebrate-vertical.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Candidate } from "../../../api/src/interfaces/Candidate";
import { getCandidate } from "../../services/getCandidate";
import { getUID, getResponseSheetName } from "../../utils/authUtils";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import QRCode, { QRCodeToDataURLOptions } from "qrcode";
import LoadingComponent from "../../components/LoadingComponent";

export default function WalkinQrResume() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  let [urlParams, setUrlParams] = useSearchParams();
  let [candidateData, setCandidateData] = useState<Partial<Candidate>>({});
  const [qrCode, setQrCode] = useState<string>("");

  const opts: QRCodeToDataURLOptions = {
    errorCorrectionLevel: "L",
    margin: 1,
    color: {
      dark: "#6a52e4",
      light: "#0000",
    },
  };

  useEffect(() => {
    // FIXME: This is always if (false)
    if (!candidateData) return;
    // if (candidateData.hasOwnProperty("email") && !candidateData.resume) {
    // TODO: Change to use a shared env var in future
    QRCode.toDataURL(
      encodeURI(`https://app.mynew.jobs/public/candidateResume?candidateId=${getUID()}&responseSheetName=${getResponseSheetName()}`),
      opts
    )
      .then((url) => {
        console.log("done");
        setQrCode(url);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
    // }
  }, [candidateData]);

  useEffect(() => {
    let uid = getUID();
    if (!uid) {
      navigate("/");
      return;
    }
    getCandidate(uid, getResponseSheetName())
      .then(({ data, error }) => {
        console.log("data", data);
        if (error) {
          throw new Error(error);
        }

        setCandidateData(data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <SimpleIphonePromptLayout>
      <div style={{ height: "20px" }}></div>
      <>
        {isLoading ? (
          <LoadingComponent style={{ gridColumn: "2 / 3" }} />
        ) : (
          <img src={qrCode} alt="qr code" />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "3rem",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              fontSize: "1.5rem",
              marginBottom: "10px",
              color: "#6a52e4",
            }}
          >
            Scan this QR Code to identify your candidate
          </div>
          <div
            style={{
              fontSize: "1rem",
              maxHeight: "200px",
              maxWidth: "200px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          ></div>
        </div>
      </>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "10px",
          width: "100%",
        }}
      >
        <Link style={{ textDecoration: "none" }} to={"/walkin/interview"}>
          <div
            // onClick={actionOnClick}
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#ececee",
              color: "#6a52e4",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            Next
          </div>
        </Link>
      </div>
    </SimpleIphonePromptLayout>
  );
}
