import React, { useState, useEffect, useCallback } from "react";
import { SETTINGS } from "../../../api/src/interfaces/sheets";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import { getResponseSheetName } from "../../utils/authUtils";
import { parseLocations } from "../../utils/settingUtils";
import useCandidate from "../../components/useCandidate";
import { convertLocationToSettings } from "../../utils/locationUtils";
import { LocationData } from "../../../api/src/interfaces/LocationData";
function generateMapEmbedSRC(latlong: string) {
  return `https://maps.google.com/maps?width=100%&height=600&hl=en&q=${
    String(latlong)
      .split(",")
      .map((x) => x.trim())[0]
  }, ${
    String(latlong)
      .split(",")
      .map((x) => x.trim())[1]
  }&z=14&ie=UTF8&iwloc=B&output=embed`;
}

type LocationType = ReturnType<typeof parseLocations>;

export default function WalkinLocation() {
  const [isLoading, setIsLoading] = useState(true);
  const [settingsData, setSettingsData] = useState<SETTINGS | null>(null);
  const [mapEmbedSRC, setMapEmbedSRC] = useState("");
  const [locations, setLocations] = useState<LocationType>([]);
  const candidate = useCandidate();
  const [activeLocationIdx, setActiveLocationIdx] = useState<number>(0);

  const nextLocation = () => {
    if (activeLocationIdx + 1 >= locations.length) {
      setActiveLocationIdx(0);
      return;
    }
    setActiveLocationIdx((old) => {
      return old + 1;
    });
  };

  useEffect(() => {
    if (locations.length <= 0) return;
    setMapEmbedSRC(
      generateMapEmbedSRC(
        locations[activeLocationIdx].setting_walkin_checkin_latlong
      )
    );
  }, [locations, activeLocationIdx]);

  useEffect(() => {
    if (!candidate) {
      return;
    }
    getSettings(getResponseSheetName())
      .then(({ data, error }) => {
        if (error) {
          throw new Error(error);
        }
        setSettingsData(data);

        let locations!: ReturnType<typeof parseLocations>;
        if (candidate?.locationAssigned) {
          locations = parseLocations(
            convertLocationToSettings(
              JSON.parse(candidate.locationAssigned) as LocationData
            )
          );
        } else {
          locations = parseLocations(data);
        }

        setLocations(locations);
        console.log(JSON.stringify({ locations }));
        setIsLoading(false);
        console.log(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, [candidate]);
  return (
    <SimpleIphonePromptLayout>
      <div style={{ fontSize: "48px" }}>Location(s)</div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div style={{ whiteSpace: "pre-wrap" }}>
            {locations[activeLocationIdx].setting_walkin_checkin_address}
          </div>
          {settingsData ? (
            <div style={{ width: "100%", maxHeight: "200px" }}>
              <div style={{ width: "100%" }}>
                <iframe
                  width="100%"
                  height="200"
                  scrolling="no"
                  src={mapEmbedSRC}
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    distance maps
                  </a>
                </iframe>
              </div>
            </div>
          ) : (
            ""
          )}

          <div
            onClick={nextLocation}
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "white",
              backgroundColor: "grey",
              borderRadius: "10px",
              padding: "10px",
              width: "100%",
            }}
          >
            See other locations
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            target="_blank"
            href={locations[activeLocationIdx].setting_walkin_checkin_gmap_link}
            rel="noreferrer"
            style={{ width: "100%", textDecoration: "none" }}
          >
            <div
              // onClick={actionOnClick}
              style={{
                cursor: "pointer",
                textAlign: "center",
                color: "white",
                backgroundColor: "#cc2802",
                borderRadius: "10px",
                padding: "10px",
                width: "100%",
              }}
            >
              Google Map
            </div>
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            target="_blank"
            href={locations[activeLocationIdx].setting_walkin_checkin_waze_link}
            rel="noreferrer"
            style={{ width: "100%", textDecoration: "none" }}
          >
            <div
              style={{
                cursor: "pointer",
                textAlign: "center",
                color: "black",
                backgroundColor: "#34ccfe",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              Waze
            </div>
          </a>
        </>
      )}
    </SimpleIphonePromptLayout>
  );
}
