import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { Link } from "react-router-dom";
export default function WalkinInterview() {
  return (
    <SimpleIphonePromptLayout>
      <div style={{ padding: "20px 0", fontWeight: "bold", fontSize: "48px" }}>
        Have you
        <br />
        complete
        <br />
        your
        <br />
        interview?
      </div>
      <Link
        to="/walkin/interviewForm"
        style={{ textDecoration: "none", width: "100%" }}
      >
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            backgroundColor: "#6656da",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          Yes
        </div>
      </Link>
      <div
        style={{
          width: "100%",
          cursor: "pointer",
          textAlign: "center",
          color: "#6656da",
          backgroundColor: "#ececee",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        Quit
      </div>
    </SimpleIphonePromptLayout>
  );
}
