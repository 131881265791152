export default function NotFoundPage() {
  return (
    <div
      style={{
        display: "flex",
        fontSize: "56px",
        alignItems: "center",
        justifyContent: "center",
        gridArea: "2 / 2 / 3 / 3",
      }}
    >
      404 Not Found
    </div>
  );
}
