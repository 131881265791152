import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import rootUrl from "../../APIRoot";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import updateCandidate from "../../services/updateCandidate";
import { getResponseSheetName, getUID } from "../../utils/authUtils";
import {
  getDistanceFromLatLonInKm,
  getUserLocationAsync,
} from "../../utils/browserUtils";
import { parseLocations } from "../../utils/settingUtils";
import useCandidate from "../../components/useCandidate";
import {
  convertLocationToSettings,
} from "../../utils/locationUtils";
import { LocationData } from "../../../api/src/interfaces/LocationData";

export default function WalkinReady() {
  const [errMsg, setErrMsg] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [checkingIn, setCheckingIn] = useState(false);
  const candidate = useCandidate();
  const navigate = useNavigate();
  const checkIn = useCallback(async () => {
    // Get user location
    let userPosition: GeolocationPosition | null = null;
    try {
      userPosition = await getUserLocationAsync();
    } catch (err) {
      console.error(err);
      throw new Error("Please allow location access to check in");
    }

    // Get settings
    let { data, error } = await getSettings(getResponseSheetName());
    if (error) {
      throw new Error("Unable to fetch settings");
    }

    let locations!: ReturnType<typeof parseLocations>;
    if (candidate?.locationAssigned) {
      locations = parseLocations(
        convertLocationToSettings(
          JSON.parse(candidate.locationAssigned) as LocationData
        )
      );
    } else {
      locations = parseLocations(data);
    }

    let found = false;
    for (const location of locations) {
      const {
        setting_walkin_checkin_range: checkInRangeMeters,
        setting_walkin_checkin_latlong,
      } = location;

      const lat = Number(
        String(setting_walkin_checkin_latlong)
          .split(",")
          .map((x) => x.trim())[0]
      );
      const long = Number(
        String(setting_walkin_checkin_latlong)
          .split(",")
          .map((x) => x.trim())[1]
      );

      // Calculate distance difference
      let userLat = userPosition.coords.latitude;
      let userLong = userPosition.coords.longitude;
      let userAcc = userPosition.coords.accuracy;

      let distanceKM = getDistanceFromLatLonInKm(userLat, userLong, lat, long);
      let maxDistance = Number(checkInRangeMeters) + userAcc; // max range (meter)
      if (distanceKM * 1000 > maxDistance) {
        // Not in range
        continue;
      }
      found = true;
    }

    // If all location out of range
    if (!found) {
      throw new Error(
        "You are currently not in range of the interview location. Please verify your location."
      );
    }

    let uid = getUID();
    if (!uid) {
      throw new Error("Unauthorized, please use link from email to authorize");
    }

    updateCandidate(
      String(uid),
      {
        checkInStatus: "checkedin",
      },
      getResponseSheetName()
    )
      .then(({ data, error }) => {
        if (error) {
          setErrMsg(error);
          setErrorDialogOpen(true);
          return;
        }
        navigate("/walkin/myresume");
      })
      .catch((err) => {
        setErrMsg("Internal server error");
        setErrorDialogOpen(true);
        return;
      });
  }, [candidate]);

  const onCheckIn = () => {
    if (checkingIn) return;
    setCheckingIn(true);
    checkIn()
      .then(() => {
        setCheckingIn(false);
      })
      .catch((err) => {
        console.error(err);
        setErrMsg(err.message);
        setErrorDialogOpen(true);
        setCheckingIn(false);
      });
  };

  const handleErrorDialogOnClose = () => {
    setErrorDialogOpen(false);
  };

  useEffect(() => {}, []);
  return (
    <SimpleIphonePromptLayout>
      <Dialog open={errorDialogOpen} onClose={handleErrorDialogOnClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errMsg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogOnClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          padding: "80px 0",
          fontWeight: "bold",
          fontSize: "48px",
        }}
      >
        Are you
        <br />
        ready to
        <br />
        check in?
      </div>

      <div
        onClick={onCheckIn}
        style={{
          width: "100%",
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          backgroundColor: checkingIn ? "grey" : "#6656da",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        {checkingIn ? "Checking in..." : "Check in"}
      </div>
      <Link
        style={{ textDecoration: "none", width: "100%" }}
        to="/walkin/location"
      >
        <div
          style={{
            width: "100%",
            cursor: "pointer",
            textAlign: "center",
            color: "#6656da",
            backgroundColor: "#ececee",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          See Location
        </div>
      </Link>
    </SimpleIphonePromptLayout>
  );
}
