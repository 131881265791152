import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { getCandidate } from "../../services/getCandidate";
import { getUID } from "../../utils/authUtils";

import HenryIMG from "./../../assets/consultants/Henry.png";
import JackyIMG from "./../../assets/consultants/Jacky.png";
import NatashaIMG from "./../../assets/consultants/Natasha.png";
import PooiJinIMG from "./../../assets/consultants/PooiJin.jpeg";
import StephenIMG from "./../../assets/consultants/Stephen.png";
import ChenShinIMG from "./../../assets/consultants/ChenShin.png";
import { getResponseSheetName } from "../../utils/authUtils";
export default function WalkinConsultant() {
  const [consultant, setConsultant] = useState({
    name: "",
    imgSrc: "",
    whatsappHref: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    let uid = getUID();
    if (!uid) {
      navigate("/");
      return;
    }
    getCandidate(uid, getResponseSheetName())
      .then(({ data, error }) => {
        if (error) throw new Error(error);
        let consultantInitial = "CS";
        let matchResult = data.statusInserted.match(/INSERTED: (.*?):/);
        if (matchResult && matchResult[1]) {
          consultantInitial = matchResult[1];
        }
        switch (String(consultantInitial).trim()) {
          case "HS":
            setConsultant({
              name: "Henry",
              imgSrc: HenryIMG,
              whatsappHref: "https://api.whatsapp.com/send?phone=60127170835",
            });
            break;
          case "NC":
            setConsultant({
              name: "Natasha",
              imgSrc: NatashaIMG,
              whatsappHref: "https://api.whatsapp.com/send?phone=60123788023",
            });
            break;
          case "SO":
            setConsultant({
              name: "Stephen",
              imgSrc: StephenIMG,
              whatsappHref: "https://api.whatsapp.com/send?phone=60162285917",
            });
            break;
          case "JS":
            setConsultant({
              name: "Jacky",
              imgSrc: PooiJinIMG,
              whatsappHref: "https://api.whatsapp.com/send?phone=60123788925",
            });
            break;
          case "CS":
            setConsultant({
              name: "ChenShin",
              imgSrc: ChenShinIMG,
              whatsappHref: "https://api.whatsapp.com/send?phone=601127518776",
            });
            break;
          default:
            setConsultant({
              name: "ChenShin",
              imgSrc: ChenShinIMG,
              whatsappHref: "https://api.whatsapp.com/send?phone=601127518776",
            });
        }
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <SimpleIphonePromptLayout>
      <div style={{ height: "20px" }}></div>

      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "3rem",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div style={{ fontSize: "1.5rem", marginBottom: "10px" }}>
            Your <span style={{ color: "#ff0200" }}>Career Consultant</span> is
          </div>
          <div
            style={{
              fontSize: "1rem",
              maxHeight: "200px",
              maxWidth: "200px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <img
              style={{ maxHeight: "100%", maxWidth: "100%" }}
              alt="consultantImage"
              src={consultant.imgSrc}
            ></img>
          </div>
          <div>{consultant.name}</div>
        </div>
      </>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "10px",
          width: "100%",
        }}
      >
        <a
          style={{ textDecoration: "none" }}
          target="_blank"
          href={consultant.whatsappHref}
          rel="noreferrer"
        >
          <div
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "white",
              backgroundColor: "#38ca1f",
              borderRadius: "10px",
              padding: "10px",
              marginBottom: "20px",
            }}
          >
            Whatsapp
          </div>
        </a>
        <Link style={{ textDecoration: "none" }} to={"/walkin/checkin"}>
          <div
            // onClick={actionOnClick}
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "white",
              backgroundColor: "#6656da",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            Check in
          </div>
        </Link>
      </div>
    </SimpleIphonePromptLayout>
  );
}
